<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="等级" prop="level">
        <el-input v-model="info.level" placeholder="请输入等级"></el-input>
      </el-form-item>
      <el-form-item label="折扣" prop="discount">
        <el-input-number controls-position="right" :min="0" :max="99" v-model="info.discount" placeholder="请输入折扣" style="width: 160px;"></el-input-number>
        <span style="color: #999; margin-left: 20px;">0~99%，80表示8折</span>
      </el-form-item>
      <el-form-item label="会员卡类型" prop="card_type">
        <el-radio-group v-model="info.card_type" :disabled="info.id?true:false">
          <el-radio label="1">自动升级</el-radio>
          <el-radio label="2">手动升级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否包邮" prop="is_postage_free">
        <el-radio-group v-model="info.is_postage_free">
          <el-radio label="0">不包邮</el-radio>
          <el-radio label="1">包邮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="升级条件" prop="amount_limit" v-if="info.card_type==1">
        <el-input v-model="info.amount_limit" placeholder="请输入升级条件">
          <template slot="prepend">消费满</template>
          <template slot="append">元升级</template>
        </el-input>
      </el-form-item>
      <el-form-item label="图标" prop="img">
        <SelectImgs :selectNum="1" :selectData="img" :selectFunc="fileChoose"></SelectImgs>
      </el-form-item>
      <el-form-item label="说明" prop="intro">
        <wang-edit :txt="info.intro" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import WangEdit from "@/components/WangEdit";
export default {
  data() {
    return {
      info: {
        id: 0,
        title: '',
        img: '',
        level: '',
        amount_limit: '',
        card_type: '1',
        intro: '',
        discount: '',
        is_postage_free: '0'
      },
      img: [],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请输入等级', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
    //
  },
  components: {
    WangEdit,
    SelectImgs
  },
  computed: {
  },
  methods: {
    setText(txt) {
      this.info.intro = txt
    },
    fileChoose(item) {
      this.info.img = item.pic
      this.img = [item.pic]
    },
    getInfo() {
      var that = this
      this.$api.member.memberGradeInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.img) {
            that.img = [that.info.img]
          }
          that.info.is_postage_free = String(that.info.is_postage_free)
          that.info.card_type = String(that.info.card_type)
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          if(this.info.id == 0) {
            this.$api.member.memberGradeAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.member.memberGradeEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 800px;
  }
</style>
